let currentUrl = window.location.href
let url = ''
currentUrl = currentUrl.substring(currentUrl.indexOf('://') + 3)
url = currentUrl.substring(0, currentUrl.indexOf('/'))
export const SERVER_API_URL = `https://${url}/krbs_kids_api/public/api/`,
SERVER_BASE_URL = `https://${url}/krbs_kids_api/public/`,
SERVER_WS_CLUSTER ='mt1',
SERVER_WS_KEY='WSKRBSKIDS20231121',
SERVER_WS_HOST='https://localhost/krbs_kids_api/public/api',
SERVER_WS_PORT=6001

