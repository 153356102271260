import Vue from 'vue'
import Vuex from 'vuex'

import LoginModule from './modules/LoginModule'
import VesselModule from './modules/VesselModule'
import TimezoneModule from './modules/TimezoneModule'
import EventModule from './modules/EventModule'
import ReportModule from './modules/ReportModule'
import ManagementOfficeModule from './modules/ManagementOfficeModule'
import CrewModule from './modules/CrewModule'
import UserModule from './modules/UserModule'
import CauseModule from './modules/CauseModule'
import FleetModule from '@/store/modules/FleetModule'
import ManagementCompanyModule from '@/store/modules/ManagementCompanyModule'
import CountryModule from '@/store/modules/CountryModule'
import ManningModule from '@/store/modules/ManningModule'
import AppModule from "@/store/modules/AppModule";
import CurrencyModule from "@/store/modules/CurrencyModule";
import RelatedPartyModule from "@/store/modules/RelatedPartyModule";
import SisterShipModule from "@/store/modules/SisterShipModule";
import MedicalTypeModule from "@/store/modules/MedicalTypeModule";
import MedicalSeverityModule from "@/store/modules/MedicalSeverityModule";
import AilmentModule from "@/store/modules/AilmentModule";
import AilmentPartModule from "@/store/modules/AilmentPartModule";
import MedicalTreatmentModule from "@/store/modules/MedicalTreatmentModule";
import AilmentReasonModule from "@/store/modules/AilmentReasonModule";
import DashboardModule from "@/store/modules/DashboardModule";
import AirCompanyInstructionModule from "@/store/modules/AirCompanyInstructionModule";
import EngineMakerModule from "@/store/modules/EngineMakerModule";
import EngineTypeModule from "@/store/modules/EngineTypeModule";
import EngineDgMakerModule from "@/store/modules/EngineDgMakerModule";
import EngineDgTypeModule from "@/store/modules/EngineDgTypeModule";
import EngineTcMakerModule from "@/store/modules/EngineTcMakerModule";
import EngineTcTypeModule from "@/store/modules/EngineTcTypeModule";
import EngineAuxMakerModule from "@/store/modules/EngineAuxMakerModule";
import EngineAuxTypeModule from "@/store/modules/EngineAuxTypeModule";
import PscModule from "@/store/modules/PscModule";
import PortModule from "@/store/modules/PortModule";
import KeysModule from "@/store/modules/KeysModule";
import CompanyInstructionModule from "@/store/modules/CompanyInstructionModule";
import AcknowledgementStatusModule from "@/store/modules/AcknowledgementStatusModule";
import JobStatusModule from "@/store/modules/JobStatusModule";
import PscReportModule from "@/store/modules/PscReportModule";

// INTERNAL AUDIT
import AuditTeamModule from "@/store/modules/AuditTeamModule";
import VesselAuditModule from "@/store/modules/VesselAuditModule";

// VESSEL VPI STATUS

import VesselVPIStatusModule from "@/store/modules/VesselVPIStatusModule";

// COMPANY AUDIT
import CompanyAuditModule from "@/store/modules/CompanyAuditModule";

//ANNOUNCEMENT
import AnnouncementModule from "@/store/modules/AnnouncementModule";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    LoginModule,
    VesselModule,
    TimezoneModule,
    EventModule,
    ReportModule,
    ManagementOfficeModule,
    CrewModule,
    UserModule,
    CauseModule,
    FleetModule,
    ManagementCompanyModule,
    CountryModule,
    ManningModule,
    AppModule,
    CurrencyModule,
    RelatedPartyModule,
    SisterShipModule,
    MedicalTypeModule,
    MedicalSeverityModule,
    AilmentModule,
    AilmentPartModule,
    MedicalTreatmentModule,
    AilmentReasonModule,
    DashboardModule,
    AirCompanyInstructionModule,
    EngineMakerModule,
    EngineTypeModule,
    EngineDgMakerModule,
    EngineDgTypeModule,
    EngineTcMakerModule,
    EngineTcTypeModule,
    EngineAuxMakerModule,
    EngineAuxTypeModule,
    PscModule,
    PortModule,
    KeysModule,
    CompanyInstructionModule,
    AcknowledgementStatusModule,
    JobStatusModule,
    PscReportModule,
    AuditTeamModule,
    VesselAuditModule,
    VesselVPIStatusModule,
    CompanyAuditModule,
    AnnouncementModule
  }
})
