<template>
  <div id="attachment-card" class="card py-1" :class="{'e-bg-green':imageInfo.isNew}" :title="imageInfo.attachment_name">
    <div class="dropdown dropright mb-2">
      <div
        data-toggle="dropdown"
        id="attachmentDropdown"
        class="text-center"
        aria-haspopup="true" style="cursor:pointer"
      >
        <div class="card-img-top text-center">
          <img :src="imageInfo.thumbnail" alt="Attachment Thumbnail" style="width: 50px">
        </div>
        <small id="attachment-name">{{imageInfo.attachment_name}}</small>
      </div>
      <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
        <button class="dropdown-item text-secondary font-weight-bolder"
                type="button"
                @click="$emit('initView',attachment)"
                v-if="isAllowed('accident-incident.attachment.images.view') && isOwnVessel()">
          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
          View Attachment
        </button>
        <button class="dropdown-item text-secondary font-weight-bolder"
                type="button"
                @click="$emit('initDownload',attachment)"
                v-if="isAllowed('accident-incident.attachment.images.download') && isOwnVessel()">
          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
          Download Attachment
        </button>
        <button class="dropdown-item  text-danger font-weight-bolder"
                @click="$emit('initDelete',attachment)"
                type="button"
                v-if="isAllowed('accident-incident.attachment.images.delete') && isOwnVessel() && canDelete">
          <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
          Delete Attachment
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: "ImageComp",
  mixins:[permissionMixin],
  data() {
    return{
      image:'',
      attachment:'',
    }
  },
  props:{
    imageInfo:{
      type:Object,
      default:null,
    },
    canDelete : {
      type : Boolean,
      default: true
    }
  },
   async created() {
     this.attachment=Object.assign({},this.imageInfo);
  },
}
</script>

<style scoped>
#attachment-card:hover > .dropdown small {
  color: white;
}

#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}

#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#attachment-card.e-bg-green  small {
  color: white;
}
</style>
