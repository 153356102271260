'use strict'

const Settings = () => import('@/views/Settings')
const SettingsUsers = () => import('@/components/settings/SettingsUsers')
const SettingsUserProfile = () => import('@/components/settings/user/Profile')
const SettingsCurrencies = () => import('@/components/settings/SettingsCurrencies')
const SettingsSisterShip = () => import('@/components/settings/SisterShip')
const SettingsEngineMaker = () => import('@/components/settings/SettingEngineMaker')
const SettingsEngineType = () => import('@/components/settings/SettingEngineType')
const SettingsDgMaker = () => import('@/components/settings/SettingsEngineDgMaker')
const SettingsDgType = () => import('@/components/settings/SettingsEngineDgType')
const SettingsTcMaker = () => import('@/components/settings/SettingsEngineTcMaker')
const SettingsTcType = () => import('@/components/settings/SettingsEngineTcType')
const SettingsAuxMaker = () => import('@/components/settings/SettingsEngineAuxMaker')
const SettingsAuxType = () => import('@/components/settings/SettingsEngineAuxType')
const SettingsRelatedParty = () => import('@/components/settings/SettingsRelatedParty')
const SettingAnnouncement = () => import('@/components/settings/announcement/Index.vue')

export const RouteSettings = {
  path: 'settings/',
  name: 'SettingsWrapper',
  component: Settings,
  redirect: 'settings/users',
  children: [
    {
      path: 'announcement',
      name: 'SettingAnnouncement',
      component: SettingAnnouncement,
    },
    {
      path: 'users',
      name: 'SettingsUsers',
      component: SettingsUsers,
    },
    {
      path: 'users/:id/',
      name: 'SettingsUserProfile',
      component: SettingsUserProfile,
      redirect: 'users/:id/role-permission',
      children: [
        {
          path: 'role-permission',
          name: 'RoleAndPermission',
          component: () => import(/* webpackChunkName: "user-change-password" */ '../components/settings/user/RoleAndPermission'),
        },
        {
          path: 'change-pass',
          name: 'ChangePassword',
          component: () => import(/* webpackChunkName: "user-change-password" */ '../components/settings/user/ChangePassword'),
        },
        {
          path: 'user-vessels',
          name: 'UserVessels',
          component: () => import(/* webpackChunkName: "user-change-password" */ '../components/settings/user/UserVessels'),
        },
        {
          path: 'reset-pass',
          name: 'ResetPassword',
          component: () => import(/* webpackChunkName: "user-change-password" */ '../components/settings/user/ResetPassword'),
        },
      ]
    },
    {
      path: 'currencies',
      name: 'SettingsCurrencies',
      component: SettingsCurrencies,
    },
    {
      path: 'events',
      name: 'SettingsEvents',
      component: SettingsUserProfile,
    },
    {
      path: 'causes',
      name: 'SettingsCauses',
      component: SettingsUserProfile,
    },
    {
      path: 'sister-ship',
      name: 'SisterShip',
      component: SettingsSisterShip,
    },

    {
      path: 'engine-maker',
      name: 'EngineMaker',
      component: SettingsEngineMaker,
    },
    {
      path: 'engine-type',
      name: 'EngineType',
      component: SettingsEngineType,
    },
    {
      path: 'engine-dg-maker',
      name: 'EngineDgMaker',
      component: SettingsDgMaker,
    },

    {
      path: 'engine-dg-type',
      name: 'EngineDgType',
      component: SettingsDgType,
    },

    {
      path: 'engine-tc-maker',
      name: 'EngineTcMaker',
      component: SettingsTcMaker,
    },

    {
      path: 'engine-tc-type',
      name: 'EngineTcType',
      component: SettingsTcType,
    },

    {
      path: 'engine-aux-maker',
      name: 'EngineAuxMaker',
      component: SettingsAuxMaker,
    },

    {
      path: 'engine-aux-type',
      name: 'EngineAuxType',
      component: SettingsAuxType,
    },
    {
      path: 'related-party',
      name: 'RelatedParty',
      component: SettingsRelatedParty,
    },
  ]
};
