import {AnnouncementService} from "@/services/AnnouncementService";

const state = {
  announcements: [],
  activeAnnouncements: [],
  announcement: null,
  latestAnnouncementTimestamp: null,
  loading: false,
  showAnnouncementModal: false,
  error: null
}

const mutations = {
  SET_ANNOUNCEMENTS: (state, payload) => state.announcements = payload,
  SET_ANNOUNCEMENT: (state, announcement) => state.announcement = announcement,
  SET_ACTIVE_ANNOUNCEMENT: (state, announcements) => state.activeAnnouncements = announcements,
  SET_LATEST_ANNOUNCEMENT_TIMESTAMP: (state, payload) => state.latestAnnouncementTimestamp = payload,
  SET_ANNOUNCEMENT_MODAL: (state, payload) => state.showAnnouncementModal = payload,
  SET_LOADING: (state, loading) => state.loading = loading,
  SET_ERROR: (state, error) => state.error = error
}

const actions = {
  async getAnnouncement(context, payload) {
    const announcement = await AnnouncementService.getAnnouncement(payload)
    context.commit('SET_ANNOUNCEMENTS', announcement.data)
    context.commit('setPagination',announcement)
  },

  async getActiveAnnouncement(context, payload) {
    const announcement = await AnnouncementService.getActiveAnnouncement()
    context.commit('SET_ACTIVE_ANNOUNCEMENT', announcement)
  },

  async getAnnouncementById({commit}, id) {
    // const announcement = await AnnouncementService.show(id)

    const announcement = state.announcements.filter(f => f.id === id)
    commit('SET_ANNOUNCEMENT', announcement[0])
  },

  async getLatestAnnouncementTimestamp({commit, dispatch}) {
    await dispatch('getActiveAnnouncement')
    console.log('test', state.activeAnnouncements)
    const a = state.activeAnnouncements

    if(a){
      const latest = new Date(Math.max.apply(null, a.map(function(e) {
        return new Date(e.updated_at);
      })));

      console.log('latest', new Date(latest).getTime())

      commit('SET_LATEST_ANNOUNCEMENT_TIMESTAMP', new Date(latest).getTime())
    } else {
      commit('SET_LATEST_ANNOUNCEMENT_TIMESTAMP', 0)
    }
  },

  toggleAnnouncementModal({commit}) {
    const value = !state.showAnnouncementModal
    commit('SET_ANNOUNCEMENT_MODAL', value)
  }
}

const getters = {
  announcements: state => state.announcements,
  announcementById: state => state.announcement,
  activeAnnouncements: state => state.activeAnnouncements,
  latestAnnouncementTimestamp:  state => state.latestAnnouncementTimestamp,
  showAnnouncementModal: state => state.showAnnouncementModal
}

export default {
  state,
  mutations,
  actions,
  getters
}
