<template>
  <div>
    <div v-if="editMode === false" style="max-height: 180px; overflow-y: auto" id="tableAttachment">
      <div class="col-12 pb-1" v-for="(attachment) in currentAttachments">
        <div class="row">
          <div :class="[attachment?.options?.metadata?.created_at !== null ? 'col-5' : 'col-7']">
            <img v-if="attachment?.options?.metadata?.thumbnail" :src="attachment?.options?.metadata?.thumbnail" class="mr-2" alt="Attachment Thumbnail"
                 style="width: 20px">{{ attachment?.options?.file?.name }}
          </div>
          <div class="col-2" v-if="attachment?.options?.metadata?.created_at !== null">
            {{ attachment?.options?.metadata?.created_at }}
          </div>
          <div class="col-2">
            {{ attachment?.options?.metadata?.file_size }}
          </div>
          <div class="col-3">
            <button class="btn btn-secondary btn-xs mr-2" @click.prevent="attachment.options.metadata.view_attachment_method"
                    v-if="viewingPermission"
            >
              View / Download Attachment
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else :class="[hideFileNames ? 'hide-all-names' : '']">
      <file-pond
        :files="currentAttachments"
        :style="{maxHeight: parseInt(maxHeight) + 'px', overflowY: 'auto'}"
        id="file-pond-component"
        name="file"
        ref="pond"
        allow-multiple="true"
        :server="serverOptions"
        :allowRevert="true"
        :allowRemove="deletingPermission"
        :accepted-file-types="acceptedFileTypes"
        :max-file-size="5000000"
        allow-process="false"
        @removefile="onRemoveFile"
        @processfilerevert="onProcessFileRevert"
        @processfile="onProcessFile"
        styleButtonRemoveItemPosition="right"
        fileValidateTypeLabelExpectedTypes="File type is not supported"
        :allowImagePreview="hideFileNames"

      >

      </file-pond>
    </div>

  </div>
</template>

<script>

import vueFilePond from 'vue-filepond'

// Import styles
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import {
  API_URL,
  DELETE_ATTACHMENT, FILE_POND_LOAD,
  FILE_POND_PROCESS,
  FILE_POND_REVERT,
  STORE_ATTACHMENTS
} from "@/services/ConstantService";
import {AlertService} from "@/services/AlertService";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);
import requestLoaderMixin from "@/mixins/requestLoaderMixin";

export default {
  name: "AttachmentsHandler",
  components : {FilePond},
  mixins : [requestLoaderMixin],
  data() {
    return {
      serverOptions: {
        load: {
          url: API_URL + FILE_POND_LOAD + '?file=',
          method : 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        },
        process: {
          url: API_URL + FILE_POND_PROCESS,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        },
        revert: {
          url: API_URL + FILE_POND_REVERT,
          method : 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        }
      },
      validFilesUniqueIds : [],
      filesToRemove: [],
    }
  },
  props : {
    acceptedFileTypes : {
      type: Array,
      default : () => []
    },
    currentAttachments : {
      type: Array,
      default : () => []
    },
    editMode : {
      type : Boolean,
      default : true
    },
    viewingPermission : {
      type: Boolean,
      default : true
    },
    deletingPermission : {
      type : Boolean,
      default : true
    },
    changes_id : {
      type : String,
      default : ''
    },
    maxHeight : {
      type : String | Number,
      default : '180px'
    },
    hideFileNames : {
      type : Boolean,
      default : false
    }
  },
  methods : {
    onProcessFileRevert(file) {
      this.validFilesUniqueIds = this.validFilesUniqueIds.filter(unique_id => unique_id !== file.serverId);
    },
    onRemoveFile(error, file) {
      if (error) {
        return;
      }
      const fileData = file.getMetadata();
      if (fileData && (file.status === 2 || file.status === 5)) {
        this.filesToRemove.push(fileData);
      }
    },
    onProcessFile(error, file) {
      if (error) {
        return;
      }
      this.validFilesUniqueIds.push(file.serverId);
    },

    isProcessingFiles() {
      const filesStatuses = this.$refs.pond.getFiles().map(file => file.status);
      return filesStatuses.includes(3) === true || filesStatuses.includes(9);
    },
    getValidFilesUniqueIds() {
      return this.validFilesUniqueIds;
    },
    getFilesToRemove() {
      return this.filesToRemove;
    },
    filesHasError() {
      const hasError = this.$refs.pond.getFiles().filter(item => item.status === 6 || item.status === 8).length > 0;

      if (hasError) {
        AlertService.errorAlert('There\'s an error from some attachments', 'UPLOADING ATTACHMENT');
        return true;
      }
      return false;
    },
    resetValues() {
      this.validFilesUniqueIds = [];
      this.filesToRemove = [];
    }
  },
  computed : {
    hasChanges() {
      return this.validFilesUniqueIds.length > 0 || this.filesToRemove.length > 0;
    }
  },
  watch : {
    hasChanges() {
      this.$emit('attachment-has-changes', this.changes_id, this.hasChanges);
    }
  },
}
</script>

<style scoped>
#tableAttachment .row:hover{
  background: #e5e3e3;
}
</style>
