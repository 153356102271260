import axios from "axios";
import {
  ACTIVE_ANNOUNCEMENT,
  ANNOUNCEMENT,
  CMS_CREW_INFO, DELETE_ANNOUNCEMENT,
  DELETE_ATTENDANCE, SHOW_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT, VESSEL_VISITATION
} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {AlertService} from "@/services/AlertService";
import urlService, {UrlService} from "@/services/UrlService";

export const AnnouncementService = {

  async getAnnouncement(payload) {
    const queryString = UrlService.setQueryString(payload)
    return await axios.get( ANNOUNCEMENT + queryString)
      .then(res => {
        return res.data ?? false
      })
      .catch(error => {
        AlertService.errorAlert('Failed to fetch announcement', 'FETCHING ANNOUNCEMENT');
        return false
      })
  },

  async getActiveAnnouncement(payload) {
    return await axios.get(ACTIVE_ANNOUNCEMENT)
      .then(res => {
        return res.data ?? false
      })
      .catch(error => {
        AlertService.errorAlert('Failed to fetch announcement', 'FETCHING ANNOUNCEMENT');
        return false
      })
  },
  async create(params) {
    const uri = ANNOUNCEMENT
    const response = await GlobalService.ajax('post', uri, params)

    return response.status === 201
  },


  async delete(params) {
    const url = DELETE_ANNOUNCEMENT.replace('{id}', params.id)

    const response = await GlobalService.ajax('post', url, params);

    if (response.status !== 204) {
      throw Error(response.message)
    }

    return response?.data
  },


  async show(id) {
    return await axios.get(SHOW_ANNOUNCEMENT.replace('{id}', id))
      .then(res => {
        return res.data ?? false
      })
      .catch(error => {
        AlertService.errorAlert('Failed to fetch announcement', 'FETCHING ANNOUNCEMENT');
        return false
      })
  },

  async update(id, params) {
    const uri = UPDATE_ANNOUNCEMENT.replace('{id}', id)
    const response = await GlobalService.ajax('patch', uri, params)

    return response.status === 200
  },

}
