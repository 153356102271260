<template>
  <div class="card p-3 shadow-sm">
    <template v-if="isLoading">
      <app-loading></app-loading>
    </template>
    <template v-else>
      <div v-if="isAllowed('accident-incident.attachment.documents.view-download') && isOwnVessel()">
        <div class="d-flex justify-content-between mb-2">
          <h6 class="font-weight-bold">Attached Reports / Documents</h6>
          <div>
            <template v-if="isAllowed('accident-incident.attachment.documents.create') && isOwnVessel()">
              <button href="#" class="e-btn e-btn-blue ml-2 mr-2" id="cancelBtn" role="button" v-if="toEditOther" @click="saveDocument" :class="saveButton.is_documents_change === false ? 'e-btn-blue-disabled' : ''"  :disabled="saveButton.is_documents_change === false">Save</button>
              <button href="#" class="e-btn e-btn-outlined-red" id="cancelBtn" role="button" v-if="toEditOther" @click="cancelUploadDocs">Cancel</button>
              <button href="#" class="e-btn e-btn-green ml-2" id="addBtn" type="button" v-else @click="toEditOther=true">Edit Documents</button>
            </template>
            <template v-if="isAllowed('accident-incident.attachment.documents.download-all') && isOwnVessel()">
              <button href="#" class="e-btn e-btn-blue ml-2" id="downloadBtn" role="button" v-show="documents.length"  v-if="!toEditOther"   @click.prevent="downloadAttachments('file')">Download All Documents</button>
            </template>
          </div>
        </div>
        <div class="row px-2 w-100" v-if="!toEditOther" id="tableAttachment">
          <div class="col-12" v-if="documents.length">
              <attachments-handler
                ref="document-attachments-handler"
                :accepted-file-types="mimes"
                :current-attachments="documentAttachments"
                :edit-mode="false"
                :viewing-permission="isAllowed('accident-incident.attachment.documents.view-download') && isOwnVessel()"
                :deleting-permission="isAllowed('accident-incident.attachment.documents.delete') && isOwnVessel()"
              />
          </div>
        </div>
        <div class="row  no-gutters" v-else>
          <div class="col-12">
            <div class="form-group">
              <attachments-handler
                ref="document-attachments-handler"
                :accepted-file-types="mimes"
                :current-attachments="documentAttachments"
                @attachment-has-changes="setButtonChange"
                :changes_id="'is_documents_change'"
                :max-height="500"
              />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <div>
        <div class="d-flex justify-content-between mb-2">
          <h6 class="font-weight-bold">Attached Images / Drawings</h6>
          <div>
            <template v-if="isAllowed('accident-incident.attachment.images.create') && isOwnVessel()">
              <button href="#" class="e-btn e-btn-blue ml-2 mr-2" id="cancelBtn" role="button" v-if="toEditImage" @click="saveDocument(false)" :class="saveButton.is_images_change === false ? 'e-btn-blue-disabled' : ''"  :disabled="saveButton.is_images_change === false">Save</button>
              <button class="e-btn e-btn-outlined-red" type="button" v-if="toEditImage" @click="cancelUploadImages">Cancel</button>
              <button class="e-btn e-btn-green ml-2" type="button" v-else @click="toEditImage=true">Edit Images</button>
            </template>
            <template v-if="isAllowed('accident-incident.attachment.images.download-all') && isOwnVessel()">
              <button class="e-btn e-btn-blue ml-2" v-show="images.length" type="submit" v-if="!toEditImage"  @click.prevent="downloadAttachments('image')">Download All Images</button>
            </template>
          </div>
        </div>
        <div class="row" v-if="!toEditImage">
          <div  v-if="images.length" class="col-lg-1 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                v-for="(attachment, attachmentIndex) in images">
            <image-comp :imageInfo="attachment"
                        :can-delete="false"
                        @initDownload="downloadImage"
                        @initView="viewImage(attachmentIndex, images, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'attachment_name',
                                         })"

            >

            </image-comp>
          </div>
        </div>
        <div class="row no-gutters" v-else>
          <div class="col-12">
            <div class="form-group">
              <attachments-handler
                ref="image-attachments-handler"
                :accepted-file-types="imageMimes"
                :current-attachments="imageAttachments"
                @attachment-has-changes="setButtonChange"
                :changes_id="'is_images_change'"
                :max-height="500"
                :hide-file-names="true"
              />

            </div>
          </div>
        </div>
        <!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
        <image-slider
          :selected-image-index="selectedImageIndex"
          :images="sliderImages"
          :visible="showSlider"
          @hideSlider="resetSlider()"
        >
        </image-slider>
      </div>
    </template>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ReportService} from "@/services/ReportService";
import {FileService} from "@/services/FileService";
import {AlertService} from "@/services/AlertService";
import AppLoading from "@/components/elements/AppLoading";
import ImageComp from "@/components/elements/ImageComp";
import OtherComp from "@/components/elements/OtherComp";
import {permissionMixin} from "@/mixins/permissionMixins";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import PscImageComponent from "@/components/modules/psc/PscImageComponent.vue";
import AttachmentsHandler from "@/components/common/AttachmentsHandler.vue";

export default {
  name: "Attachment",
  mixins:[permissionMixin, ImageSliderMixin],
  components:{
    AttachmentsHandler,
    PscImageComponent,
    ImageComp : ImageComp ,
    OtherComp : OtherComp,
    AppLoading,
    ImageSlider
  },
  data() {
    return {
      toEditImage: false,
      toEditOther:false,
      reportId: this.$route.params.id,
      isLoading:false,
      imagesUpload:0,
      filesUpload:0,
      imageMimes:[
        'image/apng',
        'image/avif',
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/webp',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/tiff',
        'image/gif',
      ],
      mimes: [
        'application/pdf,application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/zip',
        'application/octet-stream',
        'application/x-zip-compressed',
        'multipart/x-zip'
      ],
      saveButton : {
        is_documents_change : false,
        is_images_change : false,
      }
    }
  },
  computed: {
    ...mapGetters(['accidentIncidentReport','images','documents']),
    documentAttachments() {
      return this.documents.map(document => {
        return {
          source : document.path,
          options : {
            type : 'local',
            file : {
              name : document.attachment_name,
              type : document.mime_type,
              size: parseFloat(document.file_size.replace(/[^0-9.,]/g, '').replace(/,/g, '')) * 1000
            },
            metadata : {
              id : document.id,
              thumbnail : document.thumbnail,
              file_size : document.file_size,
              created_at : document.created_at,
              view_attachment_method : () => this.viewAttachment(document)
            }
          }
        }
      })
    },
    imageAttachments() {
      return this.images.map(image => {
        return {
          source : image.path,
          options : {
            type : 'local',
            metadata : {
              id : image.id,
              thumbnail : image.thumbnail,
              file_size : image.file_size,
              created_at : image.created_at
            }
          }
        }
      })
    }
  },
  async created() {
    const params = {
      reportId: this.$route.params.id
    }
    this.isLoading=true;
    params.isImage=1;
    await this.getAccidentIncidentReportAttachments(params);
    params.isImage=0
    await this.getAccidentIncidentReportAttachments(params);
    this.isLoading=false;
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport','getAccidentIncidentReportAttachments','addAttachments','deleteAttachment']),
    async cancelUploadImages(){
      if (this.saveButton.is_images_change === false) {
        this.imagesUpload=0;
        this.toEditImage = false

      } else {
        if(await AlertService.cancelAlert()){
          this.imagesUpload=0;
          this.toEditImage = false
          this.saveButton.is_images_change = false;
        }
      }
    },
    async cancelUploadDocs(){
      if (this.saveButton.is_documents_change === false) {
        this.filesUpload = 0;
        this.toEditOther = false
        this.$refs["document-attachments-handler"].resetValues();
      } else {
        if(await AlertService.cancelAlert()) {
          this.filesUpload = 0;
          this.toEditOther = false
          this.$refs["document-attachments-handler"].resetValues();
          this.saveButton.is_documents_change = false;
        }
      }
    },
    async viewAttachment(attachment) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.generateAttachment({id:attachment.id});
        if (response) {
          if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
             await FileService.viewDocument(response,attachment)
          }
          else {
            await FileService.downloadDocument(response,attachment);
          }
          swal.close();
        }
    },
    async deleteAllAttachments(filesToRemove, counter = 0) {
      if (filesToRemove.length === 0) {
        return;
      }
      await this.deleteAttachment(filesToRemove[counter]);
      counter++;
      if (counter < filesToRemove.length) {
        await this.deleteAllAttachments(filesToRemove, counter);
      }
    },
    async saveDocument(isFile=true) {
      const attachmentRef = isFile ? this.$refs['document-attachments-handler'] : this.$refs['image-attachments-handler'];
      const fileMessage = isFile ? 'Files' : 'Images'

      if (attachmentRef.isProcessingFiles() === true) {
        return AlertService.warningAlert(`Please wait for the ${fileMessage} to finish processing`, 'ATTACHMENTS PROCESSING');
      }

      const filesHasError = attachmentRef.filesHasError();
      if (filesHasError) {
        return;
      }

      swal.fire({
        'title':`Uploading ${fileMessage} <br> Please wait.....`
      })

      swal.showLoading()

      const files = attachmentRef.getValidFilesUniqueIds();
      if (files.length > 0) {
        let formData = new FormData();
        Array.prototype.forEach.call(files, file => {
          formData.append('file_unique_ids[]', file);
        })

        formData.append('id',this.reportId);
        await this.addAttachments(formData)
      }
      const filesToRemove = attachmentRef.getFilesToRemove();
      attachmentRef.resetValues();
      await this.deleteAllAttachments(filesToRemove);

      swal.close();

      await AlertService.successAlert(`${fileMessage} updated successfully`, 'UPLOAD ATTACHMENTS');
      await this.getAccidentIncidentReport(this.reportId)

      const params = {
        reportId: this.$route.params.id
      }
      if(!isFile) {
        this.toEditImage = false;
        this.saveButton.is_images_change = false;
        params.isImage=1;
        await this.getAccidentIncidentReportAttachments(params);
      }
      if(isFile) {
        this.toEditOther = false;
        this.saveButton.is_documents_change = false;
        params.isImage=0
        await this.getAccidentIncidentReportAttachments(params);

      }
    },
    async downloadImage(attachment){
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.generateAttachment({id:attachment.id});
      if (response) {
        await FileService.downloadDocument(response,attachment)
        swal.close();
      }
    },
    async downloadAttachments(file_type='image'){
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
     let response=await ReportService.downloadAttachment({
        fileType:file_type,
        id:this.$route.params.id
     })
      if(response){
          let fileBlob=FileService.base64FileToBlob(response.file,response.mime_type);
          let url = window.URL.createObjectURL(fileBlob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', response.file_name);
          document.body.appendChild(link);
          link.click();
      }
      swal.close();
    },
    setButtonChange(changeId, hasChanges) {
      this.saveButton[changeId] = hasChanges;
    }
  }
}
</script>

<style scoped>
  #tableAttachment div {
    padding: 1px 10px;
    border:0;
  }
  #tableAttachment .row:hover{
    background: #e5e3e3;
  }
</style>
